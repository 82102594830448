<template>
  <Form
    :hide-reset="edit"
    :title="title"
    :loading="loading"
    @submit="onSubmit"
    @reset="onReset"
  >
    <v-row>
      <FormItem v-model.trim="macro" type="text" name="Commessa" />

      <FormItem v-model.trim="category" type="text" name="Categoria" />

      <FormItem v-model.trim="subcategory" type="text" name="Sottocategoria" />
      <FormItem v-model="active" type="switch" name="Abilitata" />
    </v-row>
    <v-row>
      <FormCustomers
        v-model="customer_id"
        :customer_id="customer_id"
        name="Limita a Cliente"
        hint="Ricerca per ragione sociale o Partita IVA"
        persistent-hint
        clearable
      />
      <FormItem
        v-model="excluded_roles"
        :name="`Nascondi ai ruoli`"
        :hint="`I ruoli selezionati non potranno vedere questa attività`"
        :persistent-hint="true"
        type="select"
        :multiple="true"
        :values="roles"
        :clearable="true"
      />
    </v-row>
    <v-row class="mt-6">
      <FormItem
        v-model="type"
        rules="required"
        type="select"
        :values="types"
        name="Tipologia"
      />

      <FormItem
        v-model="custom_type"
        type="text"
        name="Tipo personalizzato"
        :rules="{ required: type === 'other' }"
        :disabled="type !== 'other'"
      />

      <FormItem
        v-model="avg_time"
        type="number"
        name="Durata media attività"
        suffix="minuti"
        min="0"
        max="500"
        rules="min_value:0"
      />
      <FormItem
        v-model="tw_adjustment_mode"
        rules="required"
        type="select"
        :values="[
          { value: 'none', text: 'Nessuno' },
          { value: 'fixed', text: 'Finestra fissa' },
          { value: 'half-day', text: 'Nella mezza giornata' },
          { value: 'all-day', text: 'Nella giornata Intera' },
          { value: 'mail', text: 'Supporto Via Mail' },
        ]"
        name="Aggiustamento ora appuntamento"
      />
      <FormItem
        v-model="tw_adjustment"
        type="number"
        name="Ampiezza Finestra"
        suffix="minuti"
        min="0"
        rules="min_value:0"
      />
    </v-row>
    <v-row>
      <FormItem
        v-model="priority"
        type="number"
        min="0"
        max="500"
        name="Priorità"
        rules="min_value:0"
      />
      <FormItem
        v-model="sla_1"
        type="number"
        name="SLA 1"
        hint="Valore espresso in minuti"
        suffix="minuti"
        min="0"
        rules="min_value:0"
      />
      <FormItem
        v-model="sla_2"
        type="number"
        name="SLA 2"
        hint="Valore espresso in minuti"
        suffix="minuti"
        min="0"
        rules="min_value:0"
      />
      <FormItem
        v-model="sla_3"
        type="number"
        name="SLA 3"
        hint="Valore espresso in minuti"
        suffix="minuti"
        min="0"
        rules="min_value:0"
      />
    </v-row>
    <v-row>
      <FormItem
        v-model="description"
        name="Descrizione"
        type="textarea"
        :cols="9"
        :sm="9"
        :md="9"
        :lg="9"
        :xl="9"
      />
    </v-row>
    <v-row>
      <FormItem
        v-model="appointment_required"
        type="select"
        :values="[
          { value: false, text: 'No' },
          { value: true, text: 'Si' },
        ]"
        name="Appuntamento necessario"
      />
      <FormItem
        v-model="grouping_key"
        type="text"
        name="Chiave Raggruppamento"
      />
      <v-col>
        <ChipsSelect
          v-model="distribution_list"
          label="Indirizzi email"
          hint="Inserici gli indirizzi ai quali verrà inviata una notifica in caso di nuovi ticket."
          :rules="{ required: false, email: true }"
        />
      </v-col>
    </v-row>
    <v-row>
      <FormUsers
        v-model="platform_user_id"
        :platform_user_id="platform_user_id"
        label="Utenza Ticket"
        clearable
      />
      <FormItem
        v-model="template"
        type="text"
        name="Template Email"
      />
      <FormItem v-model="magic_link" type="switch" name="Genera Magic Link" />
      <FormItem v-model="asset_required" type="switch" name="Mostra Dispositivo" />
      <FormItem v-model="external_id_required" type="switch" name="Compila Identificativo Esterno" />
    </v-row>
    <v-row>
      <v-col>
        <vue-json-editor
          v-model="additional_fields"
          :show-btns="false"
          :expanded-on-start="true"
          lang="it"
        ></vue-json-editor>
      </v-col>
    </v-row>
  </Form>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import { mapActions, mapMutations, mapState } from 'vuex'
import { negate, overEvery } from 'lodash'
import Form from '@components/forms/Form.vue'
import FormItem from '@components/forms/FormItem.vue'
import FormCustomers from '@components/forms/FormCustomers.vue'
import FormUsers from '@components/forms/FormUsers.vue'
import vueJsonEditor from 'vue-json-editor'
import ChipsSelect from '@components/forms/ChipsSelect.vue'
import { authComputed } from '@/src/state/helpers'

const { mapFields } = createHelpers({
  getterType: 'ticketsActivities/getCurrent',
  mutationType: 'ticketsActivities/SET_CURRENT_FIELDS',
})

export default {
  name: 'TicketsActivitiesForm',
  components: {
    FormCustomers,
    FormItem,
    FormUsers,
    Form,
    vueJsonEditor,
    ChipsSelect,
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Crea Nuovo',
    },
  },
  computed: {
    ...authComputed,
    ...mapFields([
      'active',
      'avg_time',
      'category',
      'customer_id',
      'id',
      'macro',
      'priority',
      'project_id',
      'excluded_roles',
      'sla_1',
      'sla_2',
      'sla_3',
      'subcategory',
      'tw_adjustment_mode',
      'tw_adjustment',
      'type',
      'description',
      'appointment_required',
      'grouping_key',
      'custom_type',
      'additional_fields',
      'distribution_list',
      'platform_user_id',
      'magic_link',
      'asset_required',
      'external_id_required',
      'template'
    ]),
    ...mapState('ticketsActivities', {
      id: (state) => state.current.id,
      loading: (state) => state.loading,
      types: (state) => state.types,
    }),
    ...mapState('roles', {
      _roles: 'list',
    }),
    roles() {
      const isCustomerRole = (role) => role.key.includes('customer')
      const isLoggedUserRole = (role) => role.key === this.currentUser.role.key
      return this._roles.filter(
        overEvery([isCustomerRole, negate(isLoggedUserRole)])
      )
    },
  },
  async mounted() {
    await this.getRoles()
  },
  beforeDestroy() {
    this.resetRoles()
  },
  methods: {
    ...mapMutations('ticketsActivities', {
      reset: 'RESET_CURRENT',
      setCurrentValues: 'SET_CURRENT',
    }),
    ...mapActions('ticketsActivities', [
      'create',
      'update',
      'setCurrent',
      'remove',
      'getItems',
    ]),
    ...mapActions('roles', {
      getRoles: 'getDropdownList',
    }),
    ...mapMutations('roles', {
      resetRoles: 'RESET_LIST',
    }),
    async onSubmit() {
      try {
        this.$emit('submit')

        if (!this.edit) {
          await this.create()
        } else {
          await this.update()
        }

        this.$emit('submitted', this.id)
      } catch (err) {
        console.log('errore', err)
      }
    },
    onReset() {
      const current = this.id
      this.reset()
      if (current) this.setCurrent(current)
      this.$emit('resetted')
    },
  },
}
</script>

<style scoped></style>
